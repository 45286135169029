/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Divider,
  Select,
  Grid,
  useToast
} from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  getMeterType,
  getProvinces,
  createPlaceStation,
  updatePlaceStation
} from 'services/place'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import PlacesAutocomplete from './PlacesAutocomplete'


const validate = (values: {
  stationName: string;
  hotelId: string;
  meterType: string;
  pricePerUnitAC: number;
  province: string;
  contactFirstName: string;
  contactLastName: string;
  contactMobile: string;
  contactEmail: string;
  placeId: string;
}) => {
  const errors: any = {};
  const inputStringPattern = /^[0-9a-zA-Zก-๙\s]+$/;
  if (values.stationName) {
    if (!inputStringPattern.test(values.stationName)) {
      errors.name = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  if (!values.stationName) {
    errors.stationName = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if (values.hotelId) {
    if (!inputStringPattern.test(values.hotelId)) {
      errors.hotelId = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  if (!values.hotelId) {
    errors.hotelId = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if (!values.meterType) {
    errors.meterType = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if (!values.pricePerUnitAC) {
    errors.pricePerUnitAC = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if (!values.province) {
    errors.province = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if (!values.placeId) {
    errors.placeId = "กรุณากรอกข้อมูลให้ครบถ้วน";
  }
  if(values.contactMobile){
    const mobilePattern = /^[0-9\b]+$/
    if(!mobilePattern.test(values.contactMobile) || values.contactMobile.length !== 10){
      errors.contactMobile = 'กรุณากรอกข้อมูลให้ถูกต้อง';
    }
  }
  if(values.contactEmail){
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if(!emailPattern.test(values.contactEmail)){
      errors.contactEmail = 'กรุณากรอกข้อมูลให้ถูกต้อง';
    }
  }
  if (values.contactFirstName) {
    if (!inputStringPattern.test(values.contactFirstName)) {
      errors.contactFirstName = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  if (values.contactLastName) {
    if (!inputStringPattern.test(values.contactLastName)) {
      errors.contactLastName = "กรุณากรอกข้อมูลให้ถูกต้อง";
    }
  }
  return errors;
};

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {lat: 13.7563309, lng: 100.5017651};
const zoom = 10
const ModalUI = (props: {
  title?: string;
  body?: string;
  fullName?: string;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  place?: any;
}) => {

  const [map, setMap] = useState(null)
  const [followPin, setFollowPin] = useState(false)
  const toast = useToast();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"]
  });


  const onLoad = useCallback(function callback(map: {
    [x: string]: any; fitBounds: (arg0: google.maps.LatLngBounds) => void; 
}) {
      if(place &&  place.lat &&  place.lng){
        setFollowPin(false)
          const setCenter = {
            lat: place.lat,
            lng: place.lng
          };
          const bounds = new window.google.maps.LatLngBounds(setCenter);
          map.fitBounds(bounds);
          map.setZoom(15);
          setMap(map);
          setMarkers(current => [
            {
              lat: place.lat,
              lng: place.lng
            }
          ])
      }else{
        map.setZoom(zoom);
        setMap(map);
      }
  }, [map])

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const [markers, setMarkers] = useState([]);

  const onMapClick = useCallback((e: {
    [x: string]: any; latLng: { lat: () => any; lng: () => any; }; 
}) => {
    if(e.placeId){
      setFollowPin(true)
      setMarkers(current => [
        {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          time: new Date()
        }
      ]);
      formik.setFieldValue("placeId", e.placeId); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { isOpen, onClose, onConfirm, title, place } = props;
  const [meterTypes, setMeterTypes] = useState([])
  const [provinces, setProvinces] = useState([])
  const formik = useFormik({
    initialValues: {
      stationName: "",
      hotelId: "",
      meterType: "",
      pricePerUnitAC: 0,
      province: "",
      contactFirstName: "",
      contactLastName: "",
      contactMobile: "",
      contactEmail: "",
      placeId: ""
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const fetchMeterType = async () => {
    try {
      const result = await getMeterType()
      if (result.status === 200) {
        setMeterTypes(result.data)
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }
  const fetchProvince = async () => {
    try {
      const result = await getProvinces()
      if (result.status === 200) {
        setProvinces(result.data)
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }
  useEffect(() => {
    fetchMeterType()
    fetchProvince()
  }, [])
  useEffect(() => {
    if(place){
      formik.setFieldValue('stationName', place.stationName);
      formik.setFieldValue('hotelId', place.hotelId);
      formik.setFieldValue('meterType', place.meterType);
      formik.setFieldValue('pricePerUnitAC', place.pricePerUnitAC);
      formik.setFieldValue('province', place.province);
      formik.setFieldValue('contactMobile', place.contactMobile);
      formik.setFieldValue('contactEmail', place.contactEmail);
      formik.setFieldValue('contactFirstName', place.contactFirstName);
      formik.setFieldValue('contactLastName', place.contactLastName);
      formik.setFieldValue('placeId', place.placeId);
    }
  }, [place])

  const changeMeterType = (event: any) => {
    formik.setFieldValue("meterType", event.target.value);
  };

  const changeProvince = async (event: any) => {
    formik.setFieldValue("province", event.target.value);
    const results = await getGeocode({ address: 'จังหวัด ' + event.target.value });
    const { lat, lng } = await getLatLng(results[0]);
    const setCenter = {
      lat: lat,
      lng: lng
    };
    const bounds = new window.google.maps.LatLngBounds(setCenter);
    map.fitBounds(bounds);
    map.setZoom(zoom);
    setMap(map);
  };

  const handleSubmit = async (values: any) => {
    formik.setSubmitting(false);
    const payload = values;
    let result
    if(place){
      result = await updatePlaceStation(payload,place.id)
    }else{
      result = await createPlaceStation(payload)
    }
    formik.setSubmitting(true);
    if(result && result.status === 200){
      setMap(null)
      formik.resetForm();
      onConfirm();
    }else if(result.status === 401){
      window.location.replace(`/auth/sign-in`);
    } else {
      toast({
        position: 'top',
        title: 'Error',
        description: result.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  };

  const closeForm = () => {
    formik.resetForm();
    onClose();
  };

  const placeSelect = (placeID:string,lat:number,lng:number) => {
    const setCenter = {
      lat: lat,
      lng: lng
    };
    const bounds = new window.google.maps.LatLngBounds(setCenter);
    map.fitBounds(bounds);
    map.setZoom(15);
    setMap(map);
    setMarkers(current => [
      {
        lat: lat,
        lng: lng,
        time: new Date()
      }
    ]);
    formik.setFieldValue("placeId", placeID); 
    setFollowPin(false)
  }
  const removePin = () => {
    setFollowPin(false)
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className="text-black mt-4 text-xl font-normal leading-7">
              {title}
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <FormControl
                isInvalid={
                  formik.errors.stationName && formik.touched.stationName
                }
              >
                <FormLabel htmlFor="stationName">
                  ชื่อสถานีชาร์จ<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุชื่อสถานีชาร์จ"
                  value={formik.values.stationName}
                  id="stationName"
                  variant="outline"
                  maxLength={100}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.stationName}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                className="mt-2"
                isInvalid={formik.errors.hotelId && formik.touched.hotelId}
              >
                <FormLabel htmlFor="hotelId">
                  Hotel ID<span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุ Hotel ID"
                  value={formik.values.hotelId}
                  id="hotelId"
                  variant="outline"
                  onChange={formik.handleChange}
                  maxLength={50}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.hotelId}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                className="mt-2"
                isInvalid={formik.errors.meterType && formik.touched.meterType}
              >
                <FormLabel htmlFor="meterType">
                  ชนิดของมิเตอร์ไฟ<span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="meterType"
                  placeholder="ระบุชนิดของมิเตอร์ไฟ"
                  value={formik.values.meterType}
                  onChange={changeMeterType}
                >
                  {meterTypes.map((val) => <option key={`meter_type_${val}`} value={val} >{val}</option>)}
                </Select>
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.meterType}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                className="mt-2"
                isInvalid={
                  formik.errors.pricePerUnitAC && formik.touched.pricePerUnitAC
                }
              >
                <FormLabel htmlFor="pricePerUnitAC">
                  ราคาค่าไฟ<span className="text-sm">(บาทต่อหน่วย)</span>
                  <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  placeholder="ระบุราคาค่าไฟ"
                  type="number"
                  value={formik.values.pricePerUnitAC}
                  id="pricePerUnitAC"
                  variant="outline"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.pricePerUnitAC}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                className="mt-2 mb-3"
                isInvalid={formik.errors.province && formik.touched.province}
              >
                <FormLabel htmlFor="province">
                  จังหวัด<span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="province"
                  placeholder="ระบุจังหวัด"
                  value={formik.values.province}
                  onChange={changeProvince}
                >
                  {provinces.map((val) => <option key={`province_${val}`} value={val} >{val}</option>)}
                </Select>
                <FormErrorMessage className="text-xs text-red-500">
                  {formik.errors.province}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                className="mt-2 mb-3"
                isInvalid={formik.errors.placeId && formik.touched.placeId }
              >
                <FormLabel htmlFor="province">
                  สถานที่ตั้งสถานีชาร์จ (Google Map)<span className="text-red-500">*</span>
                </FormLabel>
                
                {isLoaded ? (
                  <div>
                    <PlacesAutocomplete location={place && place.location} placeSelect={placeSelect} followPin={followPin} removePin={removePin}/>
                    <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    onLoad={onLoad}
                    onClick={onMapClick}
                    onUnmount={onUnmount}
                  >
                      {markers.map(marker => (
                      <Marker
                        key={`${marker.lat}-${marker.lng}`}
                        position={{ lat: marker.lat, lng: marker.lng }}
                      />
                    ))}
                    <></>
                  </GoogleMap>
                  <FormErrorMessage className="text-xs text-red-500">
                      {formik.errors.placeId}
                    </FormErrorMessage>
                  </div>
                  
              ) : <></>}
              </FormControl>
  


              <div className="mt-3">
                <h2 className="text-xl">รายละเอียดติดต่อโรงแรม</h2>
                <Grid className="mt-2" templateColumns="repeat(2, 1fr)" gap={2}>
                  <FormControl
                    isInvalid={
                      formik.errors.contactFirstName && formik.touched.contactFirstName
                    }
                  >
                    <FormLabel htmlFor="contactFirstName">
                    ชื่อจริง
                    </FormLabel>
                    <Input
                      placeholder="ระบุชื่อจริง"
                      value={formik.values.contactFirstName}
                      id="contactFirstName"
                      variant="outline"
                      onChange={formik.handleChange}
                      maxLength={50}
                    />
                    <FormErrorMessage className="text-xs text-red-500">
                      {formik.errors.contactFirstName}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.errors.contactLastName && formik.touched.contactLastName
                    }
                  >
                    <FormLabel htmlFor="contactLastName">
                    นามสกุล
                    </FormLabel>
                    <Input
                      placeholder="ระบุนามสกุล"
                      value={formik.values.contactLastName}
                      id="contactLastName"
                      variant="outline"
                      onChange={formik.handleChange}
                      maxLength={50}
                    />
                    <FormErrorMessage className="text-xs text-red-500">
                      {formik.errors.contactLastName}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.errors.contactMobile && formik.touched.contactMobile
                    }
                  >
                    <FormLabel htmlFor="contactMobile">
                    เบอร์โทร
                    </FormLabel>
                    <Input
                      placeholder="ระบุเบอร์โทร"
                      value={formik.values.contactMobile}
                      id="contactMobile"
                      variant="outline"
                      onChange={formik.handleChange}
                      maxLength={10}
                    />
                    <FormErrorMessage className="text-xs text-red-500">
                      {formik.errors.contactMobile}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.errors.contactEmail && formik.touched.contactEmail
                    }
                  >
                    <FormLabel htmlFor="contactEmail">
                    อีเมล
                    </FormLabel>
                    <Input
                      placeholder="ระบุอีเมล"
                      value={formik.values.contactEmail}
                      id="contactEmail"
                      variant="outline"
                      onChange={formik.handleChange}
                      maxLength={100}
                    />
                    <FormErrorMessage className="text-xs text-red-500">
                      {formik.errors.contactEmail}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>
              </div>
            </ModalBody>

            <ModalFooter>
              <button
                type="submit"
                // onClick={() => formik.handleSubmit()}
                className="linear mt-[1vh] h-[44px] w-full rounded-xl bg-[#1D4289] text-base font-medium text-white transition duration-200"
              >
                ยืนยัน
              </button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUI;
